export const slider = () => {
  const allSliders = document.querySelectorAll(".js-slider");
  const allSlidersArr = Array.prototype.slice.call(allSliders);

  allSlidersArr.forEach((slider) => {
    const thumbs = slider.querySelectorAll(".js-slider-thumb");
    const thumbsArr = Array.prototype.slice.call(thumbs);

    const slides = slider.querySelectorAll(".js-slider-slide");
    const slidesArr = Array.prototype.slice.call(slides);

    thumbsArr.forEach((thumb, i) => {
      thumb.addEventListener("click", () => {
        thumbsArr.forEach((thumbEl) => {
          if (thumbEl.classList.contains("is-active")) {
            thumbEl.classList.remove("is-active");
          }

          if (!thumb.classList.contains("is-active")) {
            thumb.classList.add("is-active");
          }
        });

        // スライドからis-activeクラスを削除
        slidesArr.forEach((slide) => {
          if (slide.classList.contains("is-active")) {
            slide.classList.remove("is-active");
          }
        });
        // クリックしたthumbと同じindexのスライドにis-activeクラスを付与
        slidesArr[i].classList.add("is-active");
      });
    });
  });
};
