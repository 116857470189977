export const scrollInview = () => {
  const inviewEls = document.querySelectorAll(".js-inview");
  const inviewElsArr = Array.prototype.slice.call(inviewEls);

  const cb = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inview");
        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    rootMargin: "-40% 0px",
  };

  var io = new IntersectionObserver(cb, options);

  inviewElsArr.forEach((el) => {
    io.observe(el);
  });
};
