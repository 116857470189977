export const anchorScroll = () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let headerEl = document.getElementById("header");

  anchorLinksArr.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      let headerHeight;
      if (window.getComputedStyle(headerEl).position === "fixed") {
        // PCの場合
        headerHeight = headerEl.offsetHeight;
      } else {
        // SPの場合
        headerHeight = 0;
      }
      const totalScrollAmount = targetOffsetTop - headerHeight;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: "smooth",
      });
    });
  });
};
