export const headerMenu = () => {
  const allMenuEls = document.querySelectorAll(".js-menu");
  const allMenuElsArr = Array.prototype.slice.call(allMenuEls);
  const menu1 = document.getElementById("menu-1");
  const menu2 = document.getElementById("menu-2");
  const menu3 = document.getElementById("menu-3");

  const menuOverlay = document.querySelector(".menu-overlay");
  const allMenuItemLink = document.querySelector(".menu__item-link");
  const allMenuItemLinkArr = Array.prototype.slice.call(allMenuItemLink);

  const allMenuBtnEls = document.querySelectorAll(".js-menu-btn");
  const allMenuBtnsElsArr = Array.prototype.slice.call(allMenuBtnEls);

  const allMenuBtn1 = document.querySelectorAll(".js-menu-btn-1");
  const allMenuBtn2 = document.querySelectorAll(".js-menu-btn-2");
  const allMenuBtn3 = document.querySelectorAll(".js-menu-btn-3");

  allMenuBtn1.forEach((menuBtn1) => {
    menuBtn1.addEventListener("click", () => {
      menu1.classList.toggle("is-active");
      menuBtn1.classList.toggle("is-active");
      menuOverlay.classList.add("is-active");

      if (menu2.classList.contains("is-active")) {
        menu2.classList.remove("is-active");
        allMenuBtn2.forEach((menuBtn2) => {
          menuBtn2.classList.remove("is-active");
        });
      }

      if (menu3.classList.contains("is-active")) {
        menu3.classList.remove("is-active");
        allMenuBtn3.forEach((menuBtn3) => {
          menuBtn3.classList.remove("is-active");
        });
      }
    });
  });

  allMenuBtn2.forEach((menuBtn2) => {
    menuBtn2.addEventListener("click", () => {
      menu2.classList.toggle("is-active");
      menuBtn2.classList.toggle("is-active");
      menuOverlay.classList.add("is-active");

      if (menu1.classList.contains("is-active")) {
        menu1.classList.remove("is-active");
        allMenuBtn1.forEach((menuBtn1) => {
          menuBtn1.classList.remove("is-active");
        });
      }

      if (menu3.classList.contains("is-active")) {
        menu3.classList.remove("is-active");
        allMenuBtn3.forEach((menuBtn3) => {
          menuBtn3.classList.remove("is-active");
        });
      }
    });
  });

  allMenuBtn3.forEach((menuBtn3) => {
    menuBtn3.addEventListener("click", () => {
      menu3.classList.toggle("is-active");
      menuBtn3.classList.toggle("is-active");
      menuOverlay.classList.add("is-active");

      if (menu1.classList.contains("is-active")) {
        menu1.classList.remove("is-active");
        allMenuBtn1.forEach((menuBtn1) => {
          menuBtn1.classList.remove("is-active");
        });
      }

      if (menu2.classList.contains("is-active")) {
        menu2.classList.remove("is-active");
        allMenuBtn2.forEach((menuBtn2) => {
          menuBtn2.classList.remove("is-active");
        });
      }
    });
  });

  // overlayクリック
  menuOverlay.addEventListener("click", () => {
    menuOverlay.classList.remove("is-active");

    allMenuElsArr.forEach((menu) => {
      menu.classList.remove("is-active");
    });

    allMenuBtnsElsArr.forEach((btn) => {
      btn.classList.remove("is-active");
    });
  });

  window.addEventListener("click", (e) => {
    // 製品一覧のリンクをクリックするとメニュー閉じる
    if (e.target.classList.contains("menu__item-link")) {
      menuOverlay.classList.remove("is-active");

      allMenuElsArr.forEach((menu) => {
        menu.classList.remove("is-active");
      });

      allMenuBtnsElsArr.forEach((btn) => {
        btn.classList.remove("is-active");
      });

      // コンセプト・特徴をクリックした場合
    } else if (e.target.parentElement.classList.contains("js-header-nav-link")) {
      menuOverlay.classList.remove("is-active");

      allMenuElsArr.forEach((menu) => {
        menu.classList.remove("is-active");
      });

      allMenuBtnsElsArr.forEach((btn) => {
        btn.classList.remove("is-active");
      });
    }
  });
};
