import { scrollTop } from "./modules/scrollTop";
import { header } from "./modules/header";
import { headerMenu } from "./modules/headerMenu";
import { getFullWindowHeight } from "./modules/getFullWindowHeight";
// import { footerBanner } from "./modules/footerBanner";
import { scrollInview } from "./modules/scrollInview";
import { slider } from "./modules/slider";
import { modal } from "./modules/modal";
import { anchorScroll } from "./modules/anchorScroll";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

window.addEventListener("DOMContentLoaded", () => {
  scrollTop();
  header();
  headerMenu();
  getFullWindowHeight();
  // footerBanner();
  scrollInview();
  slider();
  modal();
  anchorScroll();
});
