export const modal = () => {
  // 全てのボタン
  const allModalBtnEls = document.querySelectorAll(".js-modal-btn");
  const body = document.body;

  allModalBtnEls.forEach((modalBtn) => {
    modalBtn.addEventListener("click", () => {
      const modalBtnId = modalBtn.id;
      const modalId = modalBtnId.slice(0, -4);
      const targetModal = document.querySelector(`#${modalId}`);

      // activeクラスを付与
      targetModal.classList.add("is-active");
      body.classList.add("body--modal-active");

      // Closeボタンをクリックでモーダルを閉じる
      const modalCloseBtn = targetModal.querySelector(".js-modal-close");
      modalCloseBtn.addEventListener("click", closeModal);

      // modalの背景をクリックでモーダルを閉じる
      targetModal.addEventListener("click", (e) => {
        if (e.target.classList.contains("js-modal")) {
          closeModal();
        }
      });
    });
  });

  // メニュー内のVideoボタン
  const allMenuVideoBtns = document.querySelectorAll(".js-menu-video-btn");
  const allMenuVideoBtnsArr = Array.prototype.slice.call(allMenuVideoBtns);

  allMenuVideoBtnsArr.forEach((modalVideoBtn) => {
    modalVideoBtn.addEventListener("click", () => {
      const menuBtnId = modalVideoBtn.id;
      const menuId = menuBtnId.slice(0, -9);
      const targetModal = document.querySelector(`#${menuId}`);

      targetModal.classList.add("is-active");
      body.classList.add("body--modal-active");

      // Closeボタンをクリックでモーダルを閉じる
      const modalCloseBtn = targetModal.querySelector(".js-modal-close");
      modalCloseBtn.addEventListener("click", closeModal);

      // modalの背景をクリックでモーダルを閉じる
      targetModal.addEventListener("click", (e) => {
        if (e.target.classList.contains("js-modal")) {
          closeModal();
        }
      });
    });
  });

  function closeModal() {
    const activeModal = document.querySelector(".js-modal.is-active");
    if (activeModal) {
      activeModal.classList.remove("is-active");
      document.body.classList.remove("body--modal-active");
    }
  }
};
