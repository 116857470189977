export const scrollTop = () => {
  const scrollTopBtns = document.querySelectorAll(".js-scroll-top");
  const scrollTopBtnsArr = Array.prototype.slice.call(scrollTopBtns);

  scrollTopBtnsArr.forEach((btn) => {
    btn.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  });
};
