export const header = () => {
  const headerEl = document.getElementById("header");
  const allMenuEls = document.querySelectorAll(".js-menu");
  const allMenuElsArr = Array.prototype.slice.call(allMenuEls);

  headerPosHandler();

  window.addEventListener("scroll", () => {
    headerPosHandler();
  });

  function headerPosHandler() {
    const headerPos = headerEl.getBoundingClientRect().top + window.pageYOffset;
    if (headerPos > 0) {
      headerEl.classList.add("header--sticky");

      allMenuElsArr.forEach((menuEl) => {
        menuEl.classList.remove("menu--pt");
      });
    } else {
      headerEl.classList.remove("header--sticky");
      allMenuElsArr.forEach((menuEl) => {
        menuEl.classList.add("menu--pt");
      });
    }
  }
};
